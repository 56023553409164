import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { envToken } from '@core/services/environment';
import { IBackendError } from '@core/types/backend-error';
import { Http } from '@core/utilities/http';
import { omitEmpty } from '@core/utilities/lang';
import { getLogger, ILogger } from '@core/utilities/logger';
import { Observable, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const request: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const logger: ILogger = getLogger(`HTTP ${ req.method }`);
    const apiUrl: string = `${ inject(envToken).apiUrl }/${ req.url }`;

    const buildApiRequestUrl: <T>(request: HttpRequest<T>) => string = <T>(request: HttpRequest<T>): string => {
        if (request.method === 'GET' && request.body) {
            return `${ apiUrl }?${ Http.query(request.body) }`;
        }

        return apiUrl;
    };

    const buildApiRequestBody: <T>(request: HttpRequest<T>) => T | null = <T>(request: HttpRequest<T>): T | null =>
        request.method === 'GET' ? null : request.body;

    const getLogParams: <T>(method: string, body: T | null) => unknown = <T>(method: string, body: T | null): unknown => {
        return method === 'GET' && body ? omitEmpty(body) : body;
    };

    const handleError: (response: HttpErrorResponse) => Observable<never> = (response: HttpErrorResponse): Observable<never> => {
        const error: IBackendError = Http.getError(response);

        logger.error(
            `api: {0}, code: {1}, message: {2}, reason: {3}`,
            [
                apiUrl,
                error.code,
                error.message,
                error.reason,
            ],
        );

        return throwError(() => error);
    };

    logger.log(`api: {0}, params: {1}`, [apiUrl, getLogParams(req.method, req.body)]);

    const clonedRequest: HttpRequest<unknown> = req.clone({
        url: buildApiRequestUrl(req),
        body: buildApiRequestBody(req),
        withCredentials: false,
    });

    return next(clonedRequest).pipe(
        tap((event) => {
            if (event instanceof HttpResponse) {
                logger.log(`api: {0}, data: {1}`, [apiUrl, event.body]);
            }
        }),
        catchError((response: HttpErrorResponse) => {
            return handleError(response);
        }),
    );
};
