import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IQualification, IQualificationQuery } from '@core/domain';
import { IResponse } from '@core/types';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IQualificationsService } from './qualification.service.domain';

@Injectable({providedIn: 'root'})
export class QualificationsService implements IQualificationsService {
    private http: HttpClient = inject(HttpClient);

    private API_URL: string = 'qualifications';

    public search(qualificationQuery: IQualificationQuery): Observable<IQualification[]> {
        const body: IQualificationQuery = this.setQualificationQuery(qualificationQuery);

        return this.http.request<IResponse<{ label: string }>>('GET', this.API_URL, {body})
            .pipe(
                map((response?: IResponse<{ label: string }>) => response?.data || []),
                catchError(() => of([])),
            );
    }

    private setQualificationQuery(qualificationQuery: IQualificationQuery): IQualificationQuery {
        if (qualificationQuery.authorized_filialen_mandant_ids_only) {
            return qualificationQuery;
        }

        return {...qualificationQuery, any_filialen_mandant_ids: undefined};
    }
}
