export interface ILogger {
    log(...args: unknown[]): void;

    info(...args: unknown[]): void;

    warn(...args: unknown[]): void;

    debug(...args: unknown[]): void;

    error(...args: unknown[]): void;
}

export class BaseLogger {
    public static consoleEnabled: boolean = true;

    public static printLog(originalFn: 'log' | 'info' | 'debug' | 'warn' | 'error', result: string): void {
        const logger: Console = console;
        logger[originalFn].call(logger, result);
    }

    protected static supplant(message: string, values: unknown[]): string {
        return message.replace(/\{([^{}]*)}/g, (_: string, index: number): string => {
            const value: unknown = values[index];
            
            if (typeof value === 'object' && !(value instanceof Blob)) {
                return `\x1B[35;107;1m${ JSON.stringify(value) }\x1B[m`;
            }

            return `\x1B[35;107;1m${ value }\x1B[m`;
        });
    }

    protected static getFormattedTimestamp(date: Date): string {
        return [date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()].join(':');
    }

    protected logMessage(originalFn: 'log' | 'info' | 'debug' | 'warn' | 'error', context: string, args: unknown[]): void {
        const now: string = BaseLogger.getFormattedTimestamp(new Date());
        const supplantData: unknown[] = args.length === 1 ? [] : (args[1] as unknown[]);
        const logMessage: string = `\x1b[36m${ now } - \x1b[34m${ context }: \x1b[90m${ args[0] }`;

        this.printMessage(originalFn, BaseLogger.supplant(logMessage, supplantData));
    }

    protected printMessage(originalFn: 'log' | 'info' | 'debug' | 'warn' | 'error', message: string): void {
        if (BaseLogger.consoleEnabled) {
            BaseLogger.printLog(originalFn, message);
        }
    }
}

class Logger extends BaseLogger implements ILogger {
    constructor(protected context: string) {
        super();
    }

    public log(...args: unknown[]): void {
        this.sendMessage('log', ...args);
    }

    public info(...args: unknown[]): void {
        this.sendMessage('info', ...args);
    }

    public warn(...args: unknown[]): void {
        this.sendMessage('warn', ...args);
    }

    public debug(...args: unknown[]): void {
        this.sendMessage('debug', ...args);
    }

    public error(...args: unknown[]): void {
        this.sendMessage('error', ...args);
    }

    private sendMessage(type: 'log' | 'info' | 'debug' | 'warn' | 'error', ...args: unknown[]): void {
        this.logMessage(type, this.context, args);
    }
}

export function getLogger(context: string): ILogger {
    return new Logger(context);
}
