import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICandidate } from '@core/domain/candidate';
import { downloadFile } from '@core/utilities/dom';
import { InterpolationParameters, TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { defaultPdfRenderOptions, ICandidatePdfService, ICandidateRenderOptions } from './candidate-pdf.service.domain';

@Injectable({providedIn: 'root'})
export class CandidatePdfService implements ICandidatePdfService {
    private CANDIDATE_PDF_API: string = 'external_personal/as_pdf';

    constructor(
        private translate: TranslateService,
        private http: HttpClient,
    ) {
    }

    public download(candidate: ICandidate, candidateRenderOptions?: Partial<ICandidateRenderOptions>): void {
        // TODO: For customer/prospect we always show cover. Even for single without watchlist

        const body: ICandidateRenderOptions = {
            ...defaultPdfRenderOptions,
            ...candidateRenderOptions,
            external_personal_id: candidate.id,
        };

        lastValueFrom(this.generate(candidate, body));
    }

    private generate(candidate: ICandidate, options: ICandidateRenderOptions): Observable<void> {
        const fileName: string = this.calculatePdfFileName(candidate, options);

        return this.http.request('GET', this.CANDIDATE_PDF_API, {body: options, responseType: 'blob'})
            .pipe(map((blob: Blob) => downloadFile(blob, fileName)));
    }

    private calculatePdfFileName(candidate: ICandidate, options: ICandidateRenderOptions): string {
        const interpolationParameters: InterpolationParameters = {
            salutation: candidate.salutation || '',
            firstName: candidate.first_name || 'Anonymisiert',
            lastName: this.calculateLastName(candidate, options),
        };

        return this.translate.instant('single_candidate_pdf_file_name', interpolationParameters) as string;
    }

    // TODO: check from past model comment public readonly salutation: string; // TODO: ADJUST PDF

    private calculateLastName(candidate: ICandidate, candidateRenderOptions: ICandidateRenderOptions): string {
        // TODO: DISCUSS WITH JASCHA !!! For customer on backend side it is always true (CAN WE MAKE DEFAULT VALUES To TRUE)
        // TODO: BUT FOR DISPATCHER it can  be true/false. INCONSISTENT file name generation

        // TODO: For customer render_last_name always should be true
        if (candidateRenderOptions.render_last_name) {
            return candidate.last_name || '';
        }

        return `${ (candidate.last_name || '').slice(0, 1) }.`;
    }
}
