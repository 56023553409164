export interface IPagination {
    // TODO: MAKE AS OPTIONAL (undefined). DEFAULT ON BACKEND === 15
    limit?: number;

    offset?: number;

    is_last_page?: boolean;
}

// TODO: REMOVE AFTER CLEANUP
export const defaultPagination: IPagination = {
    limit: 15,
    offset: 0,
    is_last_page: true,
};
