import { InjectionToken } from '@angular/core';
import { ICandidate } from '@core/domain/candidate';

export const candidateServiceToken: InjectionToken<ICandidatePdfService> = new InjectionToken<ICandidatePdfService>(
    'candidateServiceToken');

export interface ICandidatePdfService {
    download(candidate: ICandidate, candidateRenderOptions?: Partial<ICandidateRenderOptions>): void;
}

export enum BusinessSegment {
    CommercialTechnical = 'commercial-technical',
    Medical = 'medical',
    Pedagogy = 'pedagogy',
    Office = 'office',
    Aviation = 'aviation'
}

export interface ICandidateRenderOptions {
    subsidiary_id?: number;

    customer_id?: string;

    external_personal_id: string;

    render_age?: boolean;

    render_last_name?: boolean;

    render_cover_page?: boolean;

    render_profile_picture?: boolean;

    business_segment?: BusinessSegment;
}

export const defaultPdfRenderOptions: Partial<ICandidateRenderOptions> = {
    render_age: true,
    render_last_name: true,
    render_cover_page: true,
    render_profile_picture: true,
    business_segment: BusinessSegment.CommercialTechnical,
};
