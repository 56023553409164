import { defaultPagination, IPagination } from '@core/types';

export interface IList<Item> {
    items: Item[];

    pagination: IPagination;
}

export function setList<T>(list?: IList<T>): IList<T> {
    return {
        items: list?.items || [] as T[],
        pagination: list?.pagination || defaultPagination,
    };
}
