import { effect, Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { getLogger, ILogger } from '@core/utilities/logger';
import { TranslateService } from '@ngx-translate/core';
import { appStorageServiceToken, IAppStorageService } from '../app-storage';
import { Country, II18nService, LanguageCode } from './i18n.service.domain';

@Injectable({providedIn: 'root'})
export class I18nService implements II18nService {
    private logger: ILogger = getLogger('I18nService');

    public country: Country = Country.Germany;

    public currentLanguage: WritableSignal<LanguageCode> = signal(this.calculateLanguage());

    constructor(@Inject(appStorageServiceToken) private appStorageService: IAppStorageService, private translate: TranslateService) {
        this.logger.log('defaultLanguage: {0}, languages: {1}', [LanguageCode.DE, this.getLanguages().toString()]);
        this.logger.log('userLanguage: {0}', [this.currentLanguage()]);
        this.translate.addLangs(this.getLanguages().slice());

        effect(() => this.onLanguageChange(this.currentLanguage()));
    }

    public setLanguage(language: LanguageCode): void {
        this.logger.info('setLanguage(); language {0}', [language]);
        this.currentLanguage.set(language);

    }

    public getLanguages(): LanguageCode[] {
        return Object.values(LanguageCode);
    }

    private onLanguageChange(language: LanguageCode): void {
        this.translate.use(language);
        this.appStorageService.setLanguage(language);
    }

    private calculateLanguage(): LanguageCode {
        const userLanguage: LanguageCode =
            this.appStorageService.getLanguage() as LanguageCode ||
            this.getDeviceLanguage() ||
            LanguageCode.DE;

        return this.getLanguages().includes(userLanguage) ? userLanguage : LanguageCode.DE;
    }

    private getDeviceLanguage(): LanguageCode {
        return this.translate.getBrowserLang() as LanguageCode;
    }
}
