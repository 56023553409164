import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@tempton/ngx-msal';
import { Observable, switchMap } from 'rxjs';

export const authorization: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    return inject(AuthService).acquireToken$(false, {}).pipe(
        switchMap((token: { accessToken: string }) => {
            return next(request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token.accessToken,
                },
            }));
        }),
    );
};
