import { IPagination } from '@core/types';
import { Observable, of, scan } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IList, setList } from '../list';

export interface IResponseMeta {
    // TODO: REMOVE AFTER WATCHLIST COUNTERS SERVICE IMPLEMENTATION
    counts_of_results: unknown;

    pagination: IPagination;
}

export interface IResponse<Item> {
    meta?: IResponseMeta;

    data?: Item[];
}

export function responseMap<T>(): (source$: Observable<IResponse<T> | null>) => Observable<IList<T>> {
    return (source$: Observable<IResponse<T> | null>): Observable<IList<T>> => source$.pipe(
        catchError(() => of(null)),
        scan((state: IList<T>, response: IResponse<T> | null) => {
            return {
                items: setItems(state, response),
                pagination: response?.meta?.pagination || state.pagination,
            };

        }, setList<T>()),
    );
}

function setItems<T>(state: IList<T>, response: IResponse<T> | null): T[] {
    if (!response?.meta?.pagination) {
        return response?.data ? response.data : state.items;
    }

    if ((state.pagination.offset || 0) < (response.meta.pagination.offset || 0)) {
        return (response?.data || []).concat(state.items);
    }

    return state.items.concat(response?.data || []);
}
