export function downloadFile(blob: Blob, name: string): void {
    const objectURL: string = window.URL.createObjectURL(blob);
    const anchor: HTMLAnchorElement = document.createElement('a');

    anchor.href = objectURL;
    anchor.download = name;
    anchor.click();

    window.URL.revokeObjectURL(objectURL);
}
