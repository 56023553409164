import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
    enableProdMode,
    importProvidersFrom,
    inject,
    provideAppInitializer,
    provideExperimentalZonelessChangeDetection,
    Provider,
} from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { authorization } from '@core/interceptors/authorization';
import { request } from '@core/interceptors/request';
import { envToken, IEnvironment } from '@core/services/environment';
import { Initializer } from '@core/services/initializer';
import { Http } from '@core/utilities/http';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { AuthConfig, AuthService } from '@tempton/ngx-msal';
import { from, Observable } from 'rxjs';
import { AppComponent } from './app/app.component';
import { TEMPTON_CONNECT_ROUTES } from './app/app.routes';
import { CORE_PROVIDERS } from './app/services/core.providers';

async function lazyLoadWorkspaceProviders(workspace: string): Promise<Provider[]> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const {SERVICE_PROVIDERS} = await import(`./app/workspace/${ workspace }/services/providers.ts`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return SERVICE_PROVIDERS;
}

class TemptonTranslateLoader implements TranslateLoader {
    constructor(public workspace: string) {
    }

    public getTranslation(lang: string): Observable<object> {
        return from(Http.getJson<object>(`/assets/i18n/${ this.workspace }/${ lang }.json`));
    }
}

Http.getJson<IEnvironment>(`/assets/environments/environment.json`)
    .then(async (env: IEnvironment) => {
        if (env.production) {
            enableProdMode();
        }

        const WORKSPACE_PROVIDERS: Provider[] = await lazyLoadWorkspaceProviders(env.workspace);

        return bootstrapApplication(AppComponent, {
            providers: [
                provideHttpClient(withInterceptors([authorization, request])),
                provideExperimentalZonelessChangeDetection(),
                importProvidersFrom(BrowserModule, BrowserAnimationsModule),
                provideTranslateService({
                    loader: {
                        provide: TranslateLoader,
                        useFactory: (): TemptonTranslateLoader => {
                            return new TemptonTranslateLoader(env.workspace);
                        },
                    },
                }),
                provideRouter(TEMPTON_CONNECT_ROUTES, withComponentInputBinding()),
                provideAppInitializer((): Observable<boolean> => inject(Initializer).init()),
                {
                    provide: envToken,
                    useValue: env,
                },

                {
                    provide: AuthConfig,
                    useValue: env.temptonNgxMsal,
                },

                {
                    provide: AuthService,
                    useClass: AuthService,
                },

                ...CORE_PROVIDERS,
                ...WORKSPACE_PROVIDERS,
            ],
        })
            // eslint-disable-next-line no-console
            .catch((error: Error) => console.error('bootstrapApplication() failed: ', error.message));
    })
    // eslint-disable-next-line no-console
    .catch((error: Error) => console.error('loadEnvironmentConfiguration() failed: ', error.message));
